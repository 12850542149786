import http from './HttpService'
import qs from 'querystring'

export const getPlans = (page, perPage, status = null) => {
  const items = {
    limit: perPage,
    offset: (page - 1) * perPage
  }

  if (status) items['status'] = status

  const query = qs.stringify(items)

  return http.get('/store/plan/?' + query)
}

export const getPlan = (id) => {
  return http.get('/store/plan/' + id + '/')
}

export const disablePlan = (id) => {
  return http.get('/store/plan/' + id + '/disable/')
}

// Get transactions of a plan
export const getPlanTransactions = (id, page, perPage) => {
  const query = qs.stringify({
    plan: id,
    limit: perPage,
    offset: (page - 1) * perPage
  })
  return http.get('/store/transaction/?' + query)
}

// Create a new plan
export const addPlan = ({ percent }) => {
  const Plan = {
    PERCENT: 'const_percent',
    NAME: 'name'
  }

  const formData = new FormData()
  formData.append(Plan.NAME, '1')
  formData.append(Plan.PERCENT, percent)

  return http.post('/store/plan/', formData)
}
