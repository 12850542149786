<script setup>
import {
  computed,
  ref,
  defineProps,
  defineEmits,
  onMounted,
  nextTick
} from 'vue'
import { useStore } from 'vuex'
import { getPlans } from '@service/PlanService'
import { validateForms, mobile, price, schemaMaker } from '@valid/JoiValidation'
import BaseInputNumber from '@common/BaseInputNumber'

const validSell1 = schemaMaker({
  mobile: mobile,
  price: price('مبلغ خرید', 10000)
})

const props = defineProps({
  sellLoading: { type: Boolean, default: false }
})
const emit = defineEmits(['submit-sell-form-1'])

const store = useStore()
const customerMobile = computed({
  get() {
    return store.state.footer.customerMobile
  },
  set(value) {
    store.commit('UPDATE_FORM_ITEMS', {
      field: 'customerMobile',
      value
    })
  }
})
const customerPrice = computed({
  get() {
    return store.state.footer.customerPrice
  },
  set(value) {
    store.commit('UPDATE_FORM_ITEMS', {
      field: 'customerPrice',
      value
    })
  }
})

// Handle focus
const input = ref(null)
const inputNumber = ref(null)

onMounted(() => {
  nextTick(() => {
    if (customerMobile.value) inputNumber.value.$el.focus()
    else input.value.$el.querySelector('input').focus()
    setTimeout(() => window.scrollTo({ top: 400 }), 100)
  })
})

// API call for plans
const btnLoading = ref(false)
const plans = computed(() => store.state.plan.sellPlans)
const submitSellInit = async () => {
  if (btnLoading.value || props.sellLoading) return

  const formItems = {
    mobile: customerMobile.value,
    price: customerPrice.value
  }
  const errors = validateForms(formItems, validSell1)

  if (errors && errors.length > 0) {
    for (let key of errors) {
      const message = { type: 'error', message: key }
      store.dispatch('addToast', message)
    }
    return
  }

  btnLoading.value = true
  try {
    if (plans.value.length === 0) {
      const { data } = await getPlans(1, 1000, 'active')
      store.dispatch('plan/setSellPlans', data.results)
    }

    const mode = plans.value.length === 1 ? 'direct' : 'select'
    emit('submit-sell-form-1', mode)
  } catch (ex) {
    if (ex.response)
      store.dispatch('addToast', {
        type: 'error',
        message: 'درخواست شما موفقیت‌آمیز نبود.'
      })
  } finally {
    btnLoading.value = false
  }
}
</script>

<template>
  <form novalidate class="modal-form-wrapper" @submit.prevent="submitSellInit">
    <div class="footer-modal">
      <h4 class="footer-modal__text">
        ثبت وفاداری
      </h4>
    </div>
    <BaseInput
      input-type="number"
      label="شماره مشتری"
      start-left
      phone-number
      ref="input"
      v-model="customerMobile"
      style="margin-top: 20px"
    />
    <BaseInputNumber
      label="مبلغ خرید"
      rial
      v-model="customerPrice"
      ref="inputNumber"
      style="margin-top: 15px;"
    />
    <div class="credit-buttons">
      <button class="button button--black" type="submit">
        <svg
          class="button__icon"
          style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd; width: 40px; height: 40px;"
          viewBox="0 0 700 512"
          v-show="!(btnLoading || sellLoading)"
        >
          <path
            fill="#c69d4e"
            d="M191.17 113.96l117.82 -0.06c7.11,-0.66 12.57,-6.62 12.57,-13.76 0,-7.13 -5.45,-13.12 -12.56,-13.76l-0.59 -0.05 -69.05 0 0.01 -73.11 -0.06 -0.65c-0.65,-7.11 -6.62,-12.57 -13.76,-12.57 -7.15,0 -13.09,5.47 -13.76,12.58l-0.05 0.56 0 73.19 -20.57 0c-50.44,0 -91.75,41.31 -91.75,91.74 0,50.43 41.31,91.75 91.75,91.75l68.76 0c35.29,0 64.12,28.81 64.12,64.1 0,35.3 -28.82,64.12 -64.12,64.12l-117.19 0 -0.64 0.06c-7.11,0.65 -12.56,6.62 -12.56,13.76 0,7.13 5.45,13.12 12.56,13.76l0.57 0.05 69.07 0 -0.01 73.15 0.06 0.6c0.67,7.11 6.61,12.58 13.76,12.58 7.14,0 13.11,-5.46 13.76,-12.57l0.05 -0.6 0 -73.16 20.57 0c50.44,0 91.75,-41.31 91.75,-91.74 0,-50.44 -41.31,-91.75 -91.75,-91.75l-68.76 0c-35.3,0 -64.11,-28.82 -64.11,-64.11 0,-35.29 28.82,-64.11 64.11,-64.11zm323.11 42.19l-27.12 0 0 86.29 -86.29 0 0 27.12 86.29 0 0 86.29 27.12 0 0 -86.29 86.3 0 0 -27.12 -86.3 0 0 -86.29z"
          />
        </svg>
        <Spinner v-if="btnLoading || sellLoading" />
      </button>
    </div>
  </form>
</template>
